import {
  ColorSchema,
  ProductOptionSchema,
  ProductOptionValueSchema,
  ProductSchema,
  ProductVariantSchema,
  SizeSchema,
  SkuSchema,
} from '@bitmap/database';
import { z } from 'zod';
import {
  IManufacturingProcess,
  IFaqCategory,
  IProduct,
  IMaterial,
  IProductHighlight,
} from '@/src/interfaces/generated/contentful';
import {
  Bundle,
  Product,
  ProductOption,
  ProductOptionValue,
  ProductRange,
  ProductVariant,
  SizeMatrix,
  Sku,
} from '@prisma/client';
import { Asset } from 'contentful';

export enum StockLevels {
  'in_stock' = 'in_stock',
  'out_of_stock' = 'out_of_stock',
  'partial_stock' = 'partial_stock',
}

export const FetchProductPriceList = z.object({
  currencyIsoCode: z.string(),
  discountNetCents: z.number(),
  discountGrossCents: z.number(),
  discountNetFormatted: z.string(),
  discountGrossFormatted: z.string(),
  priceNetCents: z.number(),
  priceOriginalNetCents: z.number(),
  priceNetFormatted: z.string(),
  priceOriginalNetFormatted: z.string(),
  priceGrossCents: z.number(),
  priceOriginalGrossCents: z.number(),
  priceGrossFormatted: z.string(),
  priceOriginalGrossFormatted: z.string(),
  salesTaxRate: z.number(),
  salesTax: z.number(),
});

export type IFetchProductPriceList = z.TypeOf<
  typeof FetchProductPriceList
> & {};

export const FetchProductPriceListByCountry = z
  .object({
    countryIso: z.string(),
    priceList: FetchProductPriceList.array(),
    appliedDiscounts: z
      .object({
        amount: z.number(),
        type: z.enum(['BUNDLE', 'STORE']),
      })
      .array(),
  })
  .array();

export type IFetchProductPriceListByCountry = z.TypeOf<
  typeof FetchProductPriceListByCountry
>;

export const ContentfulImageSchema = z.object({
  fields: z.object({
    title: z.string(),
    description: z.string(),
    file: z.object({ url: z.string() }),
  }),
});

export const FetchProductVariantGridSchema = ProductVariantSchema.pick({
  id: true,
  slug: true,
}).merge(
  z.object({
    productOptionValue: ProductOptionValueSchema.merge(
      z.object({ productOption: ProductOptionSchema }),
    ),
    skus: SkuSchema.merge(
      z.object({
        options: ProductOptionValueSchema.array(),
      }),
    ).array(),
    product: ProductSchema.pick({
      id: true,
      title: true,
      description: true,
    }).merge(
      z.object({
        priceList: FetchProductPriceListByCountry,
        sketch: ContentfulImageSchema.nullish(),
        options: ProductOptionSchema.merge(
          z.object({ values: ProductOptionValueSchema.array() }),
        ).array(),
        variants: ProductVariantSchema.merge(
          z.object({
            productOptionValue: ProductOptionValueSchema.merge(
              z.object({ productOption: ProductOptionSchema }),
            ),
          }),
        ).array(),
      }),
    ),
    images: ContentfulImageSchema.array(),
  }),
);

export type IFetchProductVariantGridSchema = z.TypeOf<
  typeof FetchProductVariantGridSchema
>;

export const FetchProductSkusSchema = SkuSchema.pick({
  id: true,
  stock: true,
});

export type IFetchProductSkusSchema = z.TypeOf<typeof FetchProductSkusSchema>;

export type IFetchProductVariantBundlesSchema = Bundle & {
  priceList: IFetchProductPriceListByCountry;
  images: Asset[];
};

export type IFetchProductVariantSchema = Pick<
  ProductVariant,
  'id' | 'isCanonical' | 'slug' | 'code' | 'contentfulId'
> & {
  productOptionValue: ProductOptionValue & {
    productOption: ProductOption;
  };
  stockLevel: StockLevels;
  cover?: Asset | null;
  images: Asset[];
  title: string;
  description: string;
  completeTheLook: IFetchProductVariantGridSchema[];
  skus: (Sku & { options: ProductOptionValue[] })[];
  bundles: IFetchProductVariantBundlesSchema[];
  careGuides: IProduct['fields']['careGuides'];
  faqs: IFaqCategory[];
  manufacturingProcess?: IManufacturingProcess;
  product: Pick<Product, 'id' | 'title'> & {
    materials: IMaterial[];
    highlights: IProductHighlight[];
    range?: ProductRange | null;
    sketch?: Asset;
    gallery: Asset[];
    prices: { market: { countryIsoCodes: string[] }; priceCentsNet: number }[];
    priceList: IFetchProductPriceListByCountry;
    options: (ProductOption & { values: ProductOptionValue[] })[];
    variants: (Pick<ProductVariant, 'id' | 'slug' | 'contentfulId'> & {
      productOptionValue: ProductOptionValue;
    })[];
    sizeMatrix: SizeMatrix;
  };
};
