import { initContract } from '@ts-rest/core';
import { checkoutContract } from './contracts/checkout.contract';
import { healthContract } from './contracts/health.contract';
import { storeSettingsContract } from './contracts/settings.contract';
import { shippingRatesContract } from './contracts/shipping-rates.contract';
import { currenciesContract } from './contracts/currencies.contract';
import { shippingDestinationsContract } from './contracts/shipping-destinations.contract';
import { notificationsContract } from './contracts/notifications.contract';

const c = initContract();

export const contracts = c.router({
  checkout: checkoutContract,
  currencies: currenciesContract,
  notififications: notificationsContract,
  shippingDestinations: shippingDestinationsContract,
  health: healthContract,
  shippingRates: shippingRatesContract,
  settings: storeSettingsContract,
});

export {
  healthContract,
  currenciesContract,
  checkoutContract,
  storeSettingsContract,
  shippingRatesContract,
};
