import { calculateCheckoutTotals } from './calculateCheckoutTotals';
import { calculateSalesTax } from './calculateSalesTax';
import { convertCmToInches } from './convertCmToInches';
import { convertToCurrency } from './convertToCurrency';
import { formatCurrency } from './formatCurrency';
import { calculateDiscount } from './calculateCheckoutTotals/calculateDiscount';
import { country } from './country';
import { calculateTotalItems } from './calculateCheckoutTotals/calculateTotalItems';
import { getActiveBundleDiscount } from './calculateCheckoutTotals/getActiveBundleDiscounts';

export const CommerceUtils = {
  calculateSalesTax,
  calculateDiscount,
  getActiveBundleDiscount,
  calculateTotalItems,
  calculateCheckoutTotals,
  convertCmToInches,
  convertToCurrency,
  formatCurrency,
  country,
};

export type { calculateCheckoutTotalsReturn } from './calculateCheckoutTotals';
