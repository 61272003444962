import { FetchCurrenciesSchema } from '../schemas/currencies.schema';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const currenciesContract = c.router({
  fetchCurrencies: {
    method: 'GET',
    path: '/currencies',
    responses: {
      200: FetchCurrenciesSchema,
    },
  },
});
