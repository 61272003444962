import { initContract } from '@ts-rest/core';

const c = initContract();

export const healthContract = c.router({
  check: {
    method: 'GET',
    path: '/health',
    responses: {
      200: c.response<{ message: string }>(),
    },
    query: null,
    summary: 'Check health',
  },
});
