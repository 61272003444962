import { IFetchCheckout } from '../../schemas/checkout.schema';
import { calculateDiscount } from './calculateDiscount';
import { calculateTotal } from './calculateTotal';
import { calculateTotalItems } from './calculateTotalItems';

export const calculateCheckoutTotals = (checkout: IFetchCheckout) => {
  const {
    lineItems,
    salesTaxRate,
    discountCode,
    selectedShippingService,
    shipping,
  } = checkout;

  const shippingUpdated = selectedShippingService ? shipping : 0;

  let valueCentsGross = 0;
  let valueCentsNet = 0;
  let salesTax = 0;

  lineItems.map(({ price, quantity }) => {
    valueCentsGross += (price?.priceGrossCents || 0) * quantity;
    valueCentsNet += (price?.priceNetCents || 0) * quantity;
    salesTax += (price?.salesTax || 0) * quantity;
  });

  const { discountGross, discountNet } = calculateDiscount({
    discount: discountCode,
    valueCentsGross,
    valueCentsNet,
  });

  const { totalGross, totalNet } = calculateTotal({
    shipping: shippingUpdated,
    subtotalGross: valueCentsGross,
    subtotalNet: valueCentsNet,
    discountGross,
    discountNet,
  });

  const totalItems = calculateTotalItems(lineItems);

  const result = {
    subtotalGross: valueCentsGross,
    subtotalNet: valueCentsNet,
    shipping: shippingUpdated,
    discountNet: discountNet,
    discountGross: discountGross,
    totalGross: totalGross,
    totalNet: totalNet,
    salesTax: salesTax,
    salesTaxRate,
    totalItems,
  };

  const hasChanged =
    result.subtotalNet === checkout.subtotalNet &&
    result.subtotalGross === checkout.subtotalGross &&
    result.shipping === checkout.shipping &&
    result.discountNet === checkout.discountNet &&
    result.discountGross === checkout.discountGross &&
    result.totalNet === checkout.totalNet &&
    result.totalGross === checkout.totalGross &&
    result.salesTax === checkout.salesTax &&
    result.salesTaxRate === checkout.salesTaxRate;

  return { ...result, hasChanged: !hasChanged };
};

export type calculateCheckoutTotalsReturn = ReturnType<
  typeof calculateCheckoutTotals
>;
