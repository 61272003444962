import { IPath } from '@/src/packages/utils/routes';
import { pathNames } from './pathNames.enum';
import { pathParams } from './pathParams.enum';

const MATERIALS_BASE = '/materials';

const CERTIFICATIONS_BASE = '/certifications';

const MAKERS_BASE = '/makers';

const PRODUCT_CARE_GUIDES_BASE = '/product-care';

const BLOG_BASE = '/journal';

const CHECKOUT_BASE = '/checkout';

const pathPrefixes = {
  legal: '/legal',
  manufacturing_processes: '/manufacturing',
};

export const paths: {
  [key in pathNames]: IPath;
} = {
  [pathNames.HOME_INDEX]: {
    path: '/',
    params: [],
  },
  [pathNames.ACCESSIBILITY_INDEX]: {
    path: `${pathPrefixes.legal}/accessibility`,
  },
  [pathNames.MANUFACTURING_INDEX]: {
    path: '/manufacturing',
  },
  [pathNames.MODERN_SLAVERY_STATEMENT_INDEX]: {
    path: `${pathPrefixes.legal}/modern-slavery`,
  },
  [pathNames.REVIEWS_INDEX]: {
    path: '/reviews',
  },
  [pathNames.NEWSLETTER_INDEX]: {
    path: '/newsletter',
  },
  [pathNames.TERMS_INDEX]: {
    path: `${pathPrefixes.legal}/terms`,
  },
  [pathNames.PRIVACY_INDEX]: {
    path: `${pathPrefixes.legal}/privacy`,
  },
  [pathNames.PRESS_INDEX]: {
    path: '/press',
  },
  [pathNames.INFLUENCERS_INDEX]: {
    path: '/influencers',
  },
  [pathNames.SIZE_GUIDES_INDEX]: {
    path: '/size-guides',
  },
  [pathNames.LIFETIME_WARRANTY_INDEX]: {
    path: '/guarantee',
  },
  [pathNames.PRODUCT_CARE_INDEX]: {
    path: '/product-care',
  },
  [pathNames.RANGES_INDEX]: {
    path: '/ranges',
    params: [],
  },
  [pathNames.COLLECTIONS_INDEX]: {
    path: '/collections',
  },
  [pathNames.PRODUCTS_INDEX]: {
    path: '/products',
  },
  [pathNames.PRODUCTS_SINGLE]: {
    path: `/products/${pathParams.PRODUCT_SINGLE}`,
    params: [pathParams.PRODUCT_SINGLE],
  },
  [pathNames.BUNDLES_INDEX]: {
    path: '/bundles',
  },
  [pathNames.BUNDLES_SINGLE]: {
    path: `/bundles/${pathParams.BUNDLE_SINGLE}`,
    params: [pathParams.BUNDLE_SINGLE],
  },
  [pathNames.RANGES_SINGLE]: {
    path: `/ranges/${pathParams.RANGE_SINGLE}`,
    params: [pathParams.RANGE_SINGLE],
  },
  [pathNames.DELIVERY_RETURNS_INDEX]: {
    path: '/delivery-returns',
  },
  [pathNames.CONTACT_INDEX]: {
    path: '/contact',
  },
  [pathNames.ABOUT_INDEX]: {
    path: '/about',
  },
  [pathNames.CHECKOUT_INDEX]: {
    path: `${CHECKOUT_BASE}/${pathParams.CHECKOUT}/details`,
    params: [pathParams.CHECKOUT],
  },
  [pathNames.CHECKOUT_SHIPPING_METHODS]: {
    path: `${CHECKOUT_BASE}/${pathParams.CHECKOUT}/shipping`,
    params: [pathParams.CHECKOUT],
  },
  [pathNames.CHECKOUT_PAYMENT]: {
    path: `${CHECKOUT_BASE}/${pathParams.CHECKOUT}/payment`,
    params: [pathParams.CHECKOUT],
  },
  [pathNames.CHECKOUT_CONFIRMATION]: {
    path: `${CHECKOUT_BASE}/${pathParams.CHECKOUT}/confirmation`,
    params: [pathParams.CHECKOUT],
  },
  [pathNames.PRODUCT_CARE_GUIDE_SINGLE]: {
    path: `${PRODUCT_CARE_GUIDES_BASE}/${pathParams.PRODUCT_CARE_GUIDE_SINGLE}`,
    params: [pathParams.PRODUCT_CARE_GUIDE_SINGLE],
  },
  [pathNames.MANUFACTURING_SINGLE]: {
    path: `${pathPrefixes.manufacturing_processes}/${pathParams.MANUFACTURING_SINGLE}`,
    params: [pathParams.MANUFACTURING_SINGLE],
  },
  [pathNames.MATERIALS_INDEX]: {
    path: `${MATERIALS_BASE}`,
  },
  [pathNames.CERTIFICATIONS_INDEX]: {
    path: CERTIFICATIONS_BASE,
  },
  [pathNames.CERTIFICATIONS_SINGLE]: {
    path: `${CERTIFICATIONS_BASE}/${pathParams.SLUG}`,
    params: [pathParams.SLUG],
  },
  [pathNames.MATERIALS_SINGLE]: {
    path: `${MATERIALS_BASE}/${pathParams.MATERIAL_SINGLE}`,
    params: [pathParams.MATERIAL_SINGLE],
  },
  [pathNames.MAKERS_INDEX]: {
    path: `${MAKERS_BASE}`,
  },
  [pathNames.MAKERS_SINGLE]: {
    path: `${MAKERS_BASE}/${pathParams.MAKER_SINGLE}`,
    params: [pathParams.MAKER_SINGLE],
  },
  [pathNames.BLOG_INDEX]: {
    path: `${BLOG_BASE}`,
  },
  [pathNames.BLOG_SINGLE]: {
    path: `${BLOG_BASE}/${pathParams.BLOG_SINGLE}`,
    params: [pathParams.BLOG_SINGLE],
  },
  [pathNames.BLOG_CATEGORY_INDEX]: {
    path: `${BLOG_BASE}/category/${pathParams.SLUG}`,
    params: [pathParams.SLUG],
  },
  [pathNames.SHOP_INDEX]: {
    path: '/shop',
  },
  [pathNames.FAQS_INDEX]: {
    path: '/faqs',
  },
};
