import { IArticle } from '@/src/interfaces/generated/contentful';
import { IPath } from './path.type';

export const ensureProtocol = (
  url: string | undefined,
  protocol: 'http' | 'https',
) => {
  if (!url) return;

  const strippedUrl = url
    .replace(/^http:\/\/|^https:\/\//i, '')
    .replace(/^\/\//, '');

  return `${protocol}://${strippedUrl}`;
};

export const getArticleImageUrl = ({
  article,
}: {
  article: IArticle | null;
}) => {
  return ensureProtocol(
    article?.fields.meta.fields.image?.fields.file.url ||
      `${article?.fields.cover.fields.file.url}?w=1200&h=630&fit=fill`,
    'https',
  );
};

export const getBaseUrl = () => {
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:4200'
    : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
};

export const getFaviconUrl = () => {
  return `${getBaseUrl()}/favicon-32x32.png`;
};

export const getRoute = (props: {
  route: IPath;
  params?: { [x: string]: string };
  searchParams?: { [key: string]: string };
  options?: { format?: 'absolute' | 'relative' };
}) => {
  const {
    route,
    params,
    searchParams,
    options = { format: 'relative' },
  } = props;

  let result = '';

  if (route.params && route.params.length > 0) {
    result = route.path;

    if (!params) throw `${route.path} requires parameters to be supplied`;

    route.params.map((param) => {
      const value = params[param];

      if (!value)
        console.log(`${route.path} is missing a parameter for ${param}`);

      result = result.replaceAll(param, value);
    });
  } else {
    result = route.path;
  }

  if (searchParams) {
    const query: string[] = [];

    Object.keys(searchParams).map((key) => {
      query.push(`${key}=${searchParams[key]}`);
    });

    query.join('&');

    result = `${result}?${query}`;
  }

  return options.format === 'relative' ? result : `${getBaseUrl()}${result}`;
};
