import { TypeOf, z } from 'zod';

const FetchStoreSettingsDto = z.object({
  name: z.string(),
  welcomeDiscount: z
    .object({
      amount: z.number(),
      type: z.string(),
    })
    .nullish(),
});

const SignupToNewsletterDto = z.object({
  email: z.string().email('Please enter a valid email address'),
  checkoutId: z.number().optional(),
});

type ISignupToNewsletterDto = TypeOf<typeof SignupToNewsletterDto>;

export { FetchStoreSettingsDto, SignupToNewsletterDto };

export type { ISignupToNewsletterDto };
