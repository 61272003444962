export const calculateTotal = ({
  subtotalNet,
  subtotalGross,
  discountNet,
  discountGross,
  shipping,
}: {
  subtotalNet: number;
  subtotalGross: number;
  discountNet: number;
  discountGross: number;
  shipping: number | null;
}) => {
  const totalNet = subtotalNet - discountNet + (shipping || 0);

  const totalGross = subtotalGross - discountGross + (shipping || 0);

  return { totalGross, totalNet };
};
