import { FetchShippingRatesDto } from '../schemas/shipping-rates.schema';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const shippingRatesContract = c.router({
  getShippingRates: {
    method: 'GET',
    path: '/shipping-rates',
    responses: {
      200: FetchShippingRatesDto,
    },
  },
});
