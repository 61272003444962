import { z } from 'zod';
import { Prisma } from '@prisma/client';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// JSON
//------------------------------------------------------

export type NullableJsonInput = Prisma.JsonValue | null | 'JsonNull' | 'DbNull' | Prisma.NullTypes.DbNull | Prisma.NullTypes.JsonNull;

export const transformJsonNull = (v?: NullableJsonInput) => {
  if (!v || v === 'DbNull') return Prisma.DbNull;
  if (v === 'JsonNull') return Prisma.JsonNull;
  return v;
};

export const JsonValue: z.ZodType<Prisma.JsonValue> = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.lazy(() => z.array(JsonValue)),
  z.lazy(() => z.record(JsonValue)),
]);

export type JsonValueType = z.infer<typeof JsonValue>;

export const NullableJsonValue = z
  .union([JsonValue, z.literal('DbNull'), z.literal('JsonNull')])
  .nullable()
  .transform((v) => transformJsonNull(v));

export type NullableJsonValueType = z.infer<typeof NullableJsonValue>;

export const InputJsonValue: z.ZodType<Prisma.InputJsonValue> = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.lazy(() => z.array(InputJsonValue.nullable())),
  z.lazy(() => z.record(InputJsonValue.nullable())),
]);

export type InputJsonValueType = z.infer<typeof InputJsonValue>;


/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const ProductScalarFieldEnumSchema = z.enum(['id','createdAt','seoTitle','title','slug','description','priceCents','contentfulId','sizeMatrixId','storeId','productRangeId']);

export const ProductOptionScalarFieldEnumSchema = z.enum(['id','name','description','required','isVariant','storeId']);

export const ProductOptionValueScalarFieldEnumSchema = z.enum(['id','name','productOptionId','storeId']);

export const ProductOptionOnProductsScalarFieldEnumSchema = z.enum(['productId','productOptionId']);

export const ProductOptionValuesOnSkusScalarFieldEnumSchema = z.enum(['skuId','productOptionValueId']);

export const ColorScalarFieldEnumSchema = z.enum(['id','type','createdAt','title','hex','storeId']);

export const SizeScalarFieldEnumSchema = z.enum(['id','createdAt','title','symbol','sizeMatrixId','order','storeId']);

export const SizeMatrixScalarFieldEnumSchema = z.enum(['id','createdAt','title','storeId']);

export const CurrencyScalarFieldEnumSchema = z.enum(['id','iso','countryIso']);

export const ProductVariantScalarFieldEnumSchema = z.enum(['id','createdAt','title','code','slug','productId','colorId','completeTheLookProductVariantGridId','storeId','isCanonical','contentfulId','productOptionValueId']);

export const ProductRangeScalarFieldEnumSchema = z.enum(['id','name','description','coverUrl','slug','createdAt','storeId','lookbookContentfulId']);

export const SkuScalarFieldEnumSchema = z.enum(['id','title','coverUrl','createdAt','stock','productId','colorId','sizeId','productVariantId','storeId']);

export const CustomerScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','email','name','newsletterSubscribeDate','newsletterUnsubscribeDate','storeId']);

export const LogScalarFieldEnumSchema = z.enum(['id','createdAt','storeId','status','service','externalId','requestBody','responseBody']);

export const AddressScalarFieldEnumSchema = z.enum(['id','createdAt','name','street1','street2','city','zip','phone','countryIsoCode','storeId']);

export const CheckoutScalarFieldEnumSchema = z.enum(['id','status','uuid','createdAt','updatedAt','customerId','addressId','paymentIntentId','paymentIntentClientSecret','discountCodeAppliedAt','discountCodeId','isGift','completedAt','storeId','ip','subtotal','subtotalGross','discount','discountGross','delivery','salesTax','salesTaxRate','total','totalGross','signupToNewsletter','shippingServiceId','currencyIso','currencyExchangeRate','trackingNumber','posthogSessionId','abandonedCartEmailId','source','storeMarketId','referrer']);

export const CheckoutLineItemScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','quantity','checkoutId','skuId','storeId']);

export const DiscountScalarFieldEnumSchema = z.enum(['id','code','createdAt','amount','type','storeId','startDate','endDate','archivedDate','isWelcomeDiscount']);

export const AssetScalarFieldEnumSchema = z.enum(['id','createdAt','url','storeId']);

export const ShipmentScalarFieldEnumSchema = z.enum(['id','createdAt','storeId','trackingCode','shippingServiceId','checkoutId']);

export const ShipmentLineItemsScalarFieldEnumSchema = z.enum(['id','createdAt','shipmentId','checkoutLineItemId','quantity']);

export const AssetEntityScalarFieldEnumSchema = z.enum(['id','assetId','type','order','productVariantId','productImagesProductId','productLifestyleImagesProductId','productRangeId','categoryId','productId']);

export const ProductVariantGridScalarFieldEnumSchema = z.enum(['id','name','createdAt']);

export const ProductVariantGridOnProductVariantsScalarFieldEnumSchema = z.enum(['productVariantId','productVariantGridId','assignedAt']);

export const CategoryScalarFieldEnumSchema = z.enum(['id','createdAt','name','description','slug','parentId','storeId']);

export const CategoriesOnProductsScalarFieldEnumSchema = z.enum(['productId','categoryId','assignedAt','assignedBy']);

export const ShippingZoneScalarFieldEnumSchema = z.enum(['id','name','countryIsoCodes','storeId']);

export const ShippingServiceScalarFieldEnumSchema = z.enum(['id','name','carrierName','priceCents','freeShippingThresholdCents','storeId']);

export const ShippingZonesOnServicesScalarFieldEnumSchema = z.enum(['zoneId','serviceId']);

export const AdminUserScalarFieldEnumSchema = z.enum(['id','createdAt','email','password']);

export const StoreCurrenciesScalarFieldEnumSchema = z.enum(['id','currencyId','storeId','currencyRate','isDefault']);

export const StoreScalarFieldEnumSchema = z.enum(['id','createdAt','name']);

export const ProductMaterialsScalarFieldEnumSchema = z.enum(['productId','materialOptionValueId','assignedAt','assignedBy']);

export const MaterialScalarFieldEnumSchema = z.enum(['id','uuid','storeId','name','createdAt']);

export const MaterialOptionScalarFieldEnumSchema = z.enum(['id','uuid','storeId','name','createdAt','materialId','default']);

export const MaterialOptionValueScalarFieldEnumSchema = z.enum(['id','uuid','storeId','name','createdAt','materialOptionId']);

export const MaterialPriceScalarFieldEnumSchema = z.enum(['id','uuid','storeId','quantityMin','quantityMax','priceCents','createdAt']);

export const SupplierScalarFieldEnumSchema = z.enum(['id','uuid','storeId','name','createdAt']);

export const PurchaseOrderScalarFieldEnumSchema = z.enum(['id','uuid','storeId','name','createdAt']);

export const WebhookScalarFieldEnumSchema = z.enum(['id','storeId','url','createdAt']);

export const WebhookLogScalarFieldEnumSchema = z.enum(['id','response','createdAt','webhookId']);

export const BundleScalarFieldEnumSchema = z.enum(['id','createdAt','name','description','slug','imagesContentfulId','metaContentfulId','discount','storeId']);

export const BundlesOnProductVariantsScalarFieldEnumSchema = z.enum(['productVariantId','bundleId']);

export const InternalApiRequestLogScalarFieldEnumSchema = z.enum(['id','createdAt','storeId','function','timeMs']);

export const ProductPriceScalarFieldEnumSchema = z.enum(['id','createdAt','priceCentsNet','storeId','productId','marketId']);

export const StoreMarketScalarFieldEnumSchema = z.enum(['id','uuid','name','createdAt','countryIsoCodes','storeId']);

export const NotificationScalarFieldEnumSchema = z.enum(['id','createdAt','email','storeId','skuId']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const JsonNullValueInputSchema = z.enum(['JsonNull',]);

export const QueryModeSchema = z.enum(['default','insensitive']);

export const NullsOrderSchema = z.enum(['first','last']);

export const JsonNullValueFilterSchema = z.enum(['DbNull','JsonNull','AnyNull',]);

export const VariantTypesSchema = z.enum(['COLOR','SCENT']);

export type VariantTypesType = `${z.infer<typeof VariantTypesSchema>}`

export const SizeSymbolsSchema = z.enum(['S','M','L']);

export type SizeSymbolsType = `${z.infer<typeof SizeSymbolsSchema>}`

export const LogStatusSchema = z.enum(['SUCCESS','ERROR']);

export type LogStatusType = `${z.infer<typeof LogStatusSchema>}`

export const DiscountTypesSchema = z.enum(['FIXED','PERCENT']);

export type DiscountTypesType = `${z.infer<typeof DiscountTypesSchema>}`

export const CheckoutStatusSchema = z.enum(['Draft','Complete','Cancelled']);

export type CheckoutStatusType = `${z.infer<typeof CheckoutStatusSchema>}`

export const AssetEntityTypeSchema = z.enum(['Product','Category']);

export type AssetEntityTypeType = `${z.infer<typeof AssetEntityTypeSchema>}`

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// PRODUCT SCHEMA
/////////////////////////////////////////

export const ProductSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  seoTitle: z.string(),
  title: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  priceCents: z.number().int(),
  contentfulId: z.string(),
  sizeMatrixId: z.number().int(),
  storeId: z.number().int(),
  productRangeId: z.number().int().nullable(),
})

export type Product = z.infer<typeof ProductSchema>

/////////////////////////////////////////
// PRODUCT OPTION SCHEMA
/////////////////////////////////////////

export const ProductOptionSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string(),
  required: z.boolean(),
  isVariant: z.boolean(),
  storeId: z.number().int().nullable(),
})

export type ProductOption = z.infer<typeof ProductOptionSchema>

/////////////////////////////////////////
// PRODUCT OPTION VALUE SCHEMA
/////////////////////////////////////////

export const ProductOptionValueSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  productOptionId: z.number().int(),
  storeId: z.number().int().nullable(),
})

export type ProductOptionValue = z.infer<typeof ProductOptionValueSchema>

/////////////////////////////////////////
// PRODUCT OPTION ON PRODUCTS SCHEMA
/////////////////////////////////////////

export const ProductOptionOnProductsSchema = z.object({
  productId: z.number().int(),
  productOptionId: z.number().int(),
})

export type ProductOptionOnProducts = z.infer<typeof ProductOptionOnProductsSchema>

/////////////////////////////////////////
// PRODUCT OPTION VALUES ON SKUS SCHEMA
/////////////////////////////////////////

export const ProductOptionValuesOnSkusSchema = z.object({
  skuId: z.number().int(),
  productOptionValueId: z.number().int(),
})

export type ProductOptionValuesOnSkus = z.infer<typeof ProductOptionValuesOnSkusSchema>

/////////////////////////////////////////
// COLOR SCHEMA
/////////////////////////////////////////

export const ColorSchema = z.object({
  type: VariantTypesSchema.nullable(),
  id: z.number().int(),
  createdAt: z.coerce.date(),
  title: z.string(),
  hex: z.string(),
  storeId: z.number().int().nullable(),
})

export type Color = z.infer<typeof ColorSchema>

/////////////////////////////////////////
// SIZE SCHEMA
/////////////////////////////////////////

export const SizeSchema = z.object({
  symbol: SizeSymbolsSchema,
  id: z.number().int(),
  createdAt: z.coerce.date(),
  title: z.string(),
  sizeMatrixId: z.number().int().nullable(),
  order: z.number().int(),
  storeId: z.number().int().nullable(),
})

export type Size = z.infer<typeof SizeSchema>

/////////////////////////////////////////
// SIZE MATRIX SCHEMA
/////////////////////////////////////////

export const SizeMatrixSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  title: z.string(),
  storeId: z.number().int().nullable(),
})

export type SizeMatrix = z.infer<typeof SizeMatrixSchema>

/////////////////////////////////////////
// CURRENCY SCHEMA
/////////////////////////////////////////

export const CurrencySchema = z.object({
  id: z.number().int(),
  iso: z.string(),
  countryIso: z.string().nullable(),
})

export type Currency = z.infer<typeof CurrencySchema>

/////////////////////////////////////////
// PRODUCT VARIANT SCHEMA
/////////////////////////////////////////

export const ProductVariantSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  title: z.string(),
  code: z.string(),
  slug: z.string(),
  productId: z.number().int(),
  colorId: z.number().int(),
  completeTheLookProductVariantGridId: z.number().int().nullable(),
  storeId: z.number().int(),
  isCanonical: z.boolean().nullable(),
  contentfulId: z.string(),
  productOptionValueId: z.number().int(),
})

export type ProductVariant = z.infer<typeof ProductVariantSchema>

/////////////////////////////////////////
// PRODUCT RANGE SCHEMA
/////////////////////////////////////////

export const ProductRangeSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string(),
  coverUrl: z.string(),
  slug: z.string(),
  createdAt: z.coerce.date(),
  storeId: z.number().int().nullable(),
  lookbookContentfulId: z.string().nullable(),
})

export type ProductRange = z.infer<typeof ProductRangeSchema>

/////////////////////////////////////////
// SKU SCHEMA
/////////////////////////////////////////

export const SkuSchema = z.object({
  id: z.number().int(),
  title: z.string(),
  coverUrl: z.string().nullable(),
  createdAt: z.coerce.date(),
  stock: z.number().int(),
  productId: z.number().int(),
  colorId: z.number().int(),
  sizeId: z.number().int(),
  productVariantId: z.number().int(),
  storeId: z.number().int(),
})

export type Sku = z.infer<typeof SkuSchema>

/////////////////////////////////////////
// CUSTOMER SCHEMA
/////////////////////////////////////////

export const CustomerSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  email: z.string(),
  name: z.string().nullable(),
  newsletterSubscribeDate: z.coerce.date().nullable(),
  newsletterUnsubscribeDate: z.coerce.date().nullable(),
  storeId: z.number().int(),
})

export type Customer = z.infer<typeof CustomerSchema>

/////////////////////////////////////////
// LOG SCHEMA
/////////////////////////////////////////

export const LogSchema = z.object({
  status: LogStatusSchema,
  id: z.number().int(),
  createdAt: z.coerce.date(),
  storeId: z.number().int(),
  service: z.string(),
  externalId: z.string().nullable(),
  requestBody: z.string(),
  responseBody: z.string(),
})

export type Log = z.infer<typeof LogSchema>

/////////////////////////////////////////
// ADDRESS SCHEMA
/////////////////////////////////////////

export const AddressSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  name: z.string().nullable(),
  street1: z.string().nullable(),
  street2: z.string().nullable(),
  city: z.string().nullable(),
  zip: z.string().nullable(),
  phone: z.string().nullable(),
  countryIsoCode: z.string(),
  storeId: z.number().int(),
})

export type Address = z.infer<typeof AddressSchema>

/////////////////////////////////////////
// CHECKOUT SCHEMA
/////////////////////////////////////////

export const CheckoutSchema = z.object({
  status: CheckoutStatusSchema,
  id: z.number().int(),
  uuid: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  customerId: z.number().int().nullable(),
  addressId: z.number().int().nullable(),
  paymentIntentId: z.string().nullable(),
  paymentIntentClientSecret: z.string().nullable(),
  discountCodeAppliedAt: z.coerce.date().nullable(),
  discountCodeId: z.number().int().nullable(),
  isGift: z.boolean(),
  completedAt: z.coerce.date().nullable(),
  storeId: z.number().int(),
  ip: z.string().nullable(),
  subtotal: z.number().int().nullable(),
  subtotalGross: z.number().int().nullable(),
  discount: z.number().int().nullable(),
  discountGross: z.number().int().nullable(),
  delivery: z.number().int().nullable(),
  salesTax: z.number().int().nullable(),
  salesTaxRate: z.number().int().nullable(),
  total: z.number().int().nullable(),
  totalGross: z.number().int().nullable(),
  signupToNewsletter: z.boolean().nullable(),
  shippingServiceId: z.number().int().nullable(),
  currencyIso: z.string(),
  currencyExchangeRate: z.number(),
  trackingNumber: z.string().nullable(),
  posthogSessionId: z.string().nullable(),
  abandonedCartEmailId: z.string().nullable(),
  source: z.string().nullable(),
  storeMarketId: z.number().int().nullable(),
  referrer: z.string().nullable(),
})

export type Checkout = z.infer<typeof CheckoutSchema>

/////////////////////////////////////////
// CHECKOUT LINE ITEM SCHEMA
/////////////////////////////////////////

export const CheckoutLineItemSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  quantity: z.number().int(),
  checkoutId: z.number().int(),
  skuId: z.number().int(),
  storeId: z.number().int(),
})

export type CheckoutLineItem = z.infer<typeof CheckoutLineItemSchema>

/////////////////////////////////////////
// DISCOUNT SCHEMA
/////////////////////////////////////////

export const DiscountSchema = z.object({
  type: DiscountTypesSchema,
  id: z.number().int(),
  code: z.string(),
  createdAt: z.coerce.date(),
  amount: z.number().int(),
  storeId: z.number().int(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date().nullable(),
  archivedDate: z.coerce.date().nullable(),
  isWelcomeDiscount: z.boolean(),
})

export type Discount = z.infer<typeof DiscountSchema>

/////////////////////////////////////////
// ASSET SCHEMA
/////////////////////////////////////////

export const AssetSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  url: z.string(),
  storeId: z.number().int().nullable(),
})

export type Asset = z.infer<typeof AssetSchema>

/////////////////////////////////////////
// SHIPMENT SCHEMA
/////////////////////////////////////////

export const ShipmentSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  storeId: z.number().int().nullable(),
  trackingCode: z.string(),
  shippingServiceId: z.number().int(),
  checkoutId: z.number().int(),
})

export type Shipment = z.infer<typeof ShipmentSchema>

/////////////////////////////////////////
// SHIPMENT LINE ITEMS SCHEMA
/////////////////////////////////////////

export const ShipmentLineItemsSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  shipmentId: z.number().int(),
  checkoutLineItemId: z.number().int(),
  quantity: z.number().int(),
})

export type ShipmentLineItems = z.infer<typeof ShipmentLineItemsSchema>

/////////////////////////////////////////
// ASSET ENTITY SCHEMA
/////////////////////////////////////////

export const AssetEntitySchema = z.object({
  type: AssetEntityTypeSchema,
  id: z.number().int(),
  assetId: z.number().int(),
  order: z.number().int().nullable(),
  productVariantId: z.number().int().nullable(),
  productImagesProductId: z.number().int().nullable(),
  productLifestyleImagesProductId: z.number().int().nullable(),
  productRangeId: z.number().int().nullable(),
  categoryId: z.number().int().nullable(),
  productId: z.number().int().nullable(),
})

export type AssetEntity = z.infer<typeof AssetEntitySchema>

/////////////////////////////////////////
// PRODUCT VARIANT GRID SCHEMA
/////////////////////////////////////////

export const ProductVariantGridSchema = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  createdAt: z.coerce.date(),
})

export type ProductVariantGrid = z.infer<typeof ProductVariantGridSchema>

/////////////////////////////////////////
// PRODUCT VARIANT GRID ON PRODUCT VARIANTS SCHEMA
/////////////////////////////////////////

export const ProductVariantGridOnProductVariantsSchema = z.object({
  productVariantId: z.number().int(),
  productVariantGridId: z.number().int(),
  assignedAt: z.coerce.date(),
})

export type ProductVariantGridOnProductVariants = z.infer<typeof ProductVariantGridOnProductVariantsSchema>

/////////////////////////////////////////
// CATEGORY SCHEMA
/////////////////////////////////////////

export const CategorySchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  name: z.string(),
  description: z.string().nullable(),
  slug: z.string(),
  parentId: z.number().int().nullable(),
  storeId: z.number().int(),
})

export type Category = z.infer<typeof CategorySchema>

/////////////////////////////////////////
// CATEGORIES ON PRODUCTS SCHEMA
/////////////////////////////////////////

export const CategoriesOnProductsSchema = z.object({
  productId: z.number().int(),
  categoryId: z.number().int(),
  assignedAt: z.coerce.date(),
  assignedBy: z.string(),
})

export type CategoriesOnProducts = z.infer<typeof CategoriesOnProductsSchema>

/////////////////////////////////////////
// SHIPPING ZONE SCHEMA
/////////////////////////////////////////

export const ShippingZoneSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  countryIsoCodes: z.string().array(),
  storeId: z.number().int(),
})

export type ShippingZone = z.infer<typeof ShippingZoneSchema>

/////////////////////////////////////////
// SHIPPING SERVICE SCHEMA
/////////////////////////////////////////

export const ShippingServiceSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  carrierName: z.string(),
  priceCents: z.number().int(),
  freeShippingThresholdCents: z.number().int().nullable(),
  storeId: z.number().int(),
})

export type ShippingService = z.infer<typeof ShippingServiceSchema>

/////////////////////////////////////////
// SHIPPING ZONES ON SERVICES SCHEMA
/////////////////////////////////////////

export const ShippingZonesOnServicesSchema = z.object({
  zoneId: z.number().int(),
  serviceId: z.number().int(),
})

export type ShippingZonesOnServices = z.infer<typeof ShippingZonesOnServicesSchema>

/////////////////////////////////////////
// ADMIN USER SCHEMA
/////////////////////////////////////////

export const AdminUserSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  email: z.string(),
  password: z.string(),
})

export type AdminUser = z.infer<typeof AdminUserSchema>

/////////////////////////////////////////
// STORE CURRENCIES SCHEMA
/////////////////////////////////////////

export const StoreCurrenciesSchema = z.object({
  id: z.number().int(),
  currencyId: z.number().int(),
  storeId: z.number().int(),
  currencyRate: z.number(),
  isDefault: z.boolean(),
})

export type StoreCurrencies = z.infer<typeof StoreCurrenciesSchema>

/////////////////////////////////////////
// STORE SCHEMA
/////////////////////////////////////////

export const StoreSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  name: z.string(),
})

export type Store = z.infer<typeof StoreSchema>

/////////////////////////////////////////
// PRODUCT MATERIALS SCHEMA
/////////////////////////////////////////

export const ProductMaterialsSchema = z.object({
  productId: z.number().int(),
  materialOptionValueId: z.number().int(),
  assignedAt: z.coerce.date(),
  assignedBy: z.string(),
})

export type ProductMaterials = z.infer<typeof ProductMaterialsSchema>

/////////////////////////////////////////
// MATERIAL SCHEMA
/////////////////////////////////////////

export const MaterialSchema = z.object({
  id: z.number().int(),
  uuid: z.string().cuid(),
  storeId: z.number().int(),
  name: z.string(),
  createdAt: z.coerce.date(),
})

export type Material = z.infer<typeof MaterialSchema>

/////////////////////////////////////////
// MATERIAL OPTION SCHEMA
/////////////////////////////////////////

export const MaterialOptionSchema = z.object({
  id: z.number().int(),
  uuid: z.string().cuid(),
  storeId: z.number().int(),
  name: z.string(),
  createdAt: z.coerce.date(),
  materialId: z.number().int(),
  default: z.boolean(),
})

export type MaterialOption = z.infer<typeof MaterialOptionSchema>

/////////////////////////////////////////
// MATERIAL OPTION VALUE SCHEMA
/////////////////////////////////////////

export const MaterialOptionValueSchema = z.object({
  id: z.number().int(),
  uuid: z.string().cuid(),
  storeId: z.number().int(),
  name: z.string(),
  createdAt: z.coerce.date(),
  materialOptionId: z.number().int(),
})

export type MaterialOptionValue = z.infer<typeof MaterialOptionValueSchema>

/////////////////////////////////////////
// MATERIAL PRICE SCHEMA
/////////////////////////////////////////

export const MaterialPriceSchema = z.object({
  id: z.number().int(),
  uuid: z.string().cuid(),
  storeId: z.number().int(),
  quantityMin: z.number().int(),
  quantityMax: z.number().int().nullable(),
  priceCents: z.number().int(),
  createdAt: z.coerce.date(),
})

export type MaterialPrice = z.infer<typeof MaterialPriceSchema>

/////////////////////////////////////////
// SUPPLIER SCHEMA
/////////////////////////////////////////

export const SupplierSchema = z.object({
  id: z.number().int(),
  uuid: z.string().cuid(),
  storeId: z.number().int(),
  name: z.string(),
  createdAt: z.coerce.date(),
})

export type Supplier = z.infer<typeof SupplierSchema>

/////////////////////////////////////////
// PURCHASE ORDER SCHEMA
/////////////////////////////////////////

export const PurchaseOrderSchema = z.object({
  id: z.number().int(),
  uuid: z.string().cuid(),
  storeId: z.number().int(),
  name: z.string(),
  createdAt: z.coerce.date(),
})

export type PurchaseOrder = z.infer<typeof PurchaseOrderSchema>

/////////////////////////////////////////
// WEBHOOK SCHEMA
/////////////////////////////////////////

export const WebhookSchema = z.object({
  id: z.number().int(),
  storeId: z.number().int(),
  url: z.string(),
  createdAt: z.coerce.date(),
})

export type Webhook = z.infer<typeof WebhookSchema>

/////////////////////////////////////////
// WEBHOOK LOG SCHEMA
/////////////////////////////////////////

export const WebhookLogSchema = z.object({
  id: z.number().int(),
  response: InputJsonValue,
  createdAt: z.coerce.date(),
  webhookId: z.number().int(),
})

export type WebhookLog = z.infer<typeof WebhookLogSchema>

/////////////////////////////////////////
// BUNDLE SCHEMA
/////////////////////////////////////////

export const BundleSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  name: z.string(),
  description: z.string().nullable(),
  slug: z.string(),
  imagesContentfulId: z.string(),
  metaContentfulId: z.string(),
  discount: z.number().int(),
  storeId: z.number().int(),
})

export type Bundle = z.infer<typeof BundleSchema>

/////////////////////////////////////////
// BUNDLES ON PRODUCT VARIANTS SCHEMA
/////////////////////////////////////////

export const BundlesOnProductVariantsSchema = z.object({
  productVariantId: z.number().int(),
  bundleId: z.number().int(),
})

export type BundlesOnProductVariants = z.infer<typeof BundlesOnProductVariantsSchema>

/////////////////////////////////////////
// INTERNAL API REQUEST LOG SCHEMA
/////////////////////////////////////////

export const InternalApiRequestLogSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  storeId: z.number().int(),
  function: z.string(),
  timeMs: z.number().int(),
})

export type InternalApiRequestLog = z.infer<typeof InternalApiRequestLogSchema>

/////////////////////////////////////////
// PRODUCT PRICE SCHEMA
/////////////////////////////////////////

export const ProductPriceSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  priceCentsNet: z.number().int(),
  storeId: z.number().int(),
  productId: z.number().int(),
  marketId: z.number().int(),
})

export type ProductPrice = z.infer<typeof ProductPriceSchema>

/////////////////////////////////////////
// STORE MARKET SCHEMA
/////////////////////////////////////////

export const StoreMarketSchema = z.object({
  id: z.number().int(),
  uuid: z.string().cuid(),
  name: z.string(),
  createdAt: z.coerce.date(),
  countryIsoCodes: z.string().array(),
  storeId: z.number().int(),
})

export type StoreMarket = z.infer<typeof StoreMarketSchema>

/////////////////////////////////////////
// NOTIFICATION SCHEMA
/////////////////////////////////////////

export const NotificationSchema = z.object({
  id: z.number().int(),
  createdAt: z.coerce.date(),
  email: z.string(),
  storeId: z.number().int(),
  skuId: z.number().int(),
})

export type Notification = z.infer<typeof NotificationSchema>
