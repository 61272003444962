import { DiscountTypes } from '@prisma/client';

export const calculateDiscount = ({
  discount,
  valueCentsNet,
  valueCentsGross,
}: {
  discount: { amount: number; type: DiscountTypes } | null;
  valueCentsNet: number;
  valueCentsGross: number;
}) => {
  let discountGross = 0;
  let discountNet = 0;

  if (
    discount?.amount &&
    typeof discount.amount === 'number' &&
    valueCentsNet >= 0 &&
    valueCentsGross >= 0
  ) {
    const isFixedDiscount = discount.type === DiscountTypes.FIXED;

    const discountAmount = isFixedDiscount
      ? discount.amount * 100
      : discount.amount / 100;

    discountNet = isFixedDiscount
      ? discountAmount
      : Math.floor(valueCentsNet * discountAmount);

    discountGross = isFixedDiscount
      ? discountAmount
      : Math.floor(valueCentsGross * discountAmount);
  }

  return {
    discountGross,
    discountNet,
  };
};
