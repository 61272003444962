import { initContract } from '@ts-rest/core';
import {
  AddLineItemsRequestDto,
  ApplyDiscountCodeRequestDto,
  CreateCheckoutRequestDto,
  DeleteLineItemstRequestDto,
  FetchCheckoutResponseDto,
  SetCheckoutDeliveryAddressCountry,
  SetCheckoutDeliveryAddressRequestDto,
  SetCheckoutShippingMethodRequestDto,
  UpdateCurrencyRequestDto,
} from '../schemas/checkout.schema';
import { z } from 'zod';

const c = initContract();

export const checkoutContract = c.router({
  create: {
    method: 'POST',
    path: '/checkout',
    body: CreateCheckoutRequestDto,
    responses: {
      200: FetchCheckoutResponseDto,
    },
  },
  addLineItem: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/line-items',
    body: AddLineItemsRequestDto,
    responses: {
      200: FetchCheckoutResponseDto,
    },
  },
  removeLineItem: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/line-items/:lineItemId',
    body: DeleteLineItemstRequestDto,
    responses: {
      200: FetchCheckoutResponseDto,
    },
  },
  setDeliveryAddress: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/set-delivery-address',
    body: SetCheckoutDeliveryAddressRequestDto,
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
    },
  },
  setDeliveryCountry: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/set-delivery-country',
    body: SetCheckoutDeliveryAddressCountry,
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
    },
  },
  setShippingMethod: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/set-shipping-method',
    body: SetCheckoutShippingMethodRequestDto,
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
    },
  },
  getCheckout: {
    method: 'GET',
    path: '/checkout/:checkoutUUID',
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
      400: z.object({ message: z.string() }),
    },
  },
  applyDiscount: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/apply-discount',
    body: ApplyDiscountCodeRequestDto,
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
    },
  },
  removeDiscount: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/remove-discount',
    body: null,
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
    },
  },
  updateCurrency: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/update-currency',
    body: UpdateCurrencyRequestDto,
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
    },
  },
  complete: {
    method: 'POST',
    path: '/checkout/:checkoutUUID/complete',
    body: null,
    responses: {
      200: FetchCheckoutResponseDto.nullable(),
    },
  },
});
