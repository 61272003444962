import {
  FetchProductPriceList,
  FetchProductPriceListByCountry,
} from '../schemas/products.schema';
import {
  CheckoutSchema,
  AddressSchema,
  DiscountSchema,
  CustomerSchema,
  CheckoutLineItemSchema,
  ShippingServiceSchema,
  ProductOptionSchema,
  ProductOptionValueSchema,
} from '../../../prisma-schema';
import { TypeOf, boolean, number, object, string, z } from 'zod';
import { ErrorMessages } from '../error-messages.enum';

export const CreateCheckoutRequestDto = object({
  currencyIso: string(),
  shippingDestinationIso: string(),
  posthogSessionId: string().optional(),
  referrer: string().optional(),
});

export type ICreateCheckoutRequestDto = z.TypeOf<
  typeof CreateCheckoutRequestDto
>;

export const UpdateCurrencyRequestDto = z.object({ currencyIso: z.string() });

export type IUpdateCurrencyRequestDto = z.TypeOf<
  typeof UpdateCurrencyRequestDto
>;

export const AddLineItemsRequestDto = CheckoutLineItemSchema.pick({
  quantity: true,
  skuId: true,
}).array();

export type IAddLineItemsRequestDto = z.TypeOf<typeof AddLineItemsRequestDto>;

export const SetCheckoutShippingMethodRequestDto = z.object({
  shippingServiceId: z.number(),
});

export const SetCheckoutDeliveryAddressCountry = z.object({
  countryIso: z.string(),
});

export type ISetCheckoutDeliveryAddressCountry = z.TypeOf<
  typeof SetCheckoutDeliveryAddressCountry
>;

export const SetCheckoutDeliveryAddressRequestDto = z
  .object({
    buyerName: z.string().min(1, ErrorMessages.required),
    recipientName: z.string().min(1, ErrorMessages.required),
    email: z.string().email(ErrorMessages.invalid_email),
    street1: z.string().min(1, ErrorMessages.required),
    street2: z.string(),
    city: z.string().min(1, ErrorMessages.required),
    zip: z.string().min(1, ErrorMessages.required),
    countryIsoCode: z.string().min(2).max(2),
    phone: z.string(),
    signupToNewsletter: z.boolean(),
    isGift: z.boolean(),
    source: z.string().optional(),
  })
  .required({
    buyerName: true,
    recipientName: true,
    email: true,
    street1: true,
    city: true,
    zip: true,
    countryIsoCode: true,
    phone: true,
  });

export type ISetCheckoutDeliveryAddress = z.TypeOf<
  typeof SetCheckoutDeliveryAddressRequestDto
>;

export const DeleteLineItemstRequestDto = z.object({
  quantity: z.number(),
});

export type IDeleteLineItemsRequest = TypeOf<typeof DeleteLineItemstRequestDto>;

export const ApplyDiscountCodeRequestDto = z.object({
  discountCode: z.string(),
});

export type IApplyDiscountCodeRequestDto = TypeOf<
  typeof ApplyDiscountCodeRequestDto
>;

export const FetchCheckoutResponseDto = CheckoutSchema.pick({
  uuid: true,
  paymentIntentClientSecret: true,
  paymentIntentId: true,
  isGift: true,
  signupToNewsletter: true,
  currencyIso: true,
  currencyExchangeRate: true,
  status: true,
  completedAt: true,
  addressId: true,
  posthogSessionId: true,
  referrer: true,
  source: true,
}).merge(
  z.object({
    createdAt: z.string(),
    customer: CustomerSchema.pick({ name: true, email: true }).nullable(),
    bundleDiscounts: z
      .object({
        id: z.number(),
        discount: z.number(),
        variants: z
          .object({
            id: z.number(),
          })
          .array(),
      })
      .array(),
    address: AddressSchema.pick({
      name: true,
      street1: true,
      street2: true,
      zip: true,
      city: true,
      countryIsoCode: true,
      phone: true,
    }).nullable(),
    lineItems: z
      .object({
        id: z.number(),
        quantity: z.number(),
        price: FetchProductPriceList.merge(
          z.object({
            priceNetMultipleCents: z.number(),
            priceOriginalNetMultipleCents: z.number(),
            priceNetMultipleFormatted: z.string(),
            priceOriginalNetMultipleFormatted: z.string(),
            priceGrossMultipleCents: z.number(),
            priceOriginalGrossMultipleCents: z.number(),
            priceGrossMultipleFormatted: z.string(),
            priceOriginalGrossMultipleFormatted: z.string(),
          }),
        ),
        sku: z.object({
          id: z.number(),
          title: z.string(),
          coverUrl: z.string().nullable(),
          productVariantId: z.number(),
          options: ProductOptionValueSchema.merge(
            z.object({ productOption: ProductOptionSchema }),
          ).array(),
          product: z.object({
            images: z.array(z.object({ asset: z.object({ url: z.string() }) })),
          }),
          productVariant: z.object({
            slug: z.string(),
            images: z.array(z.object({ asset: z.object({ url: z.string() }) })),
          }),
        }),
      })
      .array(),
    discountCode: DiscountSchema.pick({
      code: true,
      amount: true,
      type: true,
    }).nullable(),
    selectedShippingService: ShippingServiceSchema.nullable(),
    shippingServices: z.array(
      ShippingServiceSchema.merge(
        z.object({
          price: FetchProductPriceList,
          freeShippingBalanceCents: number(),
          freeShippingBalanceFormatted: string(),
          freeShippingThresholdMet: boolean(),
          freeShippingThresholdCompletePercentage: number(),
        }),
      ),
    ),
    isAddressSet: z.boolean(),
    subtotalGross: z.number(),
    subtotalGrossFormatted: z.string(),
    subtotalNet: z.number(),
    subtotalNetFormatted: z.string(),
    shipping: z.number(),
    shippingFormatted: z.string(),
    discountNet: z.number(),
    discountNetFormatted: z.string(),
    discountGross: z.number(),
    discountGrossFormatted: z.string(),
    totalGross: z.number(),
    totalGrossFormatted: z.string(),
    totalNet: z.number(),
    totalNetFormatted: z.string(),
    salesTax: z.number(),
    salesTaxFormatted: z.string(),
    salesTaxRate: z.number(),
    totalItems: z.number(),
  }),
);

export type IFetchCheckout = z.TypeOf<typeof FetchCheckoutResponseDto>;
