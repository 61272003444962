import { FetchProductPriceListByCountry } from './products.schema';
import {
  ShippingServiceSchema,
  ShippingZoneSchema,
} from '../../../prisma-schema/src';
import { TypeOf, z } from 'zod';

export const FetchShippingRatesDto = ShippingZoneSchema.merge(
  z.object({
    services: z.array(
      z.object({
        service: ShippingServiceSchema.merge(
          z.object({
            priceLists: FetchProductPriceListByCountry,
            freeShippingThresholds: FetchProductPriceListByCountry,
          })
        ),
        zone: ShippingZoneSchema,
      })
    ),
  })
).array();

export type IFetchShippingRates = TypeOf<typeof FetchShippingRatesDto>;
