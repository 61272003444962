export enum pathParams {
  'SLUG' = ':slug',
  'MATERIAL_SINGLE' = ':materialSlug',
  'MAKER_SINGLE' = ':makerSlug',
  'BLOG_SINGLE' = ':blogEntrySlug',
  'PRODUCT_CARE_GUIDE_SINGLE' = ':productCareGuideSlug',
  'PRODUCT_SINGLE' = ':slug',
  'BUNDLE_SINGLE' = ':slug',
  'RANGE_SINGLE' = ':slug',
  'MANUFACTURING_SINGLE' = ':slug',
  'CHECKOUT' = ':checkoutId',
}
