import { TypeOf, z } from 'zod';

export const FetchShippingDestinationsDto = z
  .object({
    continent: z.string().nullable(),
    region: z.string().nullable(),
    country: z.string().nullable(),
    capital: z.string().nullable(),
    fips: z.string().nullable(),
    iso2: z.string(),
    iso3: z.string().nullable(),
    isoNo: z.string().nullable(),
    internet: z.string().nullable(),
  })
  .array();

export type IFetchShippingDestinations = TypeOf<
  typeof FetchShippingDestinationsDto
>;
