import getSymbolFromCurrency from 'currency-symbol-map';
import { convertCentsToDisplayPrice } from '../convertCentsToDisplayPrice';

export const formatCurrency = (
  valueCents: number | null | undefined,
  currencyIso: string
) => {
  const symbol = getSymbolFromCurrency(currencyIso);

  return `${symbol}${convertCentsToDisplayPrice(valueCents || 0)}`;
};
