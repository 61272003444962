export const calculateSalesTax = ({
  valueCentsNet,
  salesTaxRatePercentage,
}: {
  valueCentsNet: number;
  salesTaxRatePercentage: number;
}) => {
  const salesTax = Math.floor(valueCentsNet * (salesTaxRatePercentage / 100));


  return {
    salesTax: salesTax,
    salesTaxRatePercentage,
    valueCentsNet,
    valueCentsGross: valueCentsNet + salesTax,
  };
};
