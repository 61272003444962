import { CreateNotificationSchema } from '../schemas/notifications.schema';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const notificationsContract = c.router({
  create: {
    method: 'POST',
    path: '/notifications',
    responses: {
      200: null,
    },
    body: CreateNotificationSchema,
  },
});
