type IGetActiveBundleDiscountProps = {
  bundles: {
    id: number;
    discount: number;
    variants: { id: number }[];
  }[];
  lineItems: { sku: { productVariantId: number } }[];
};

export const getActiveBundleDiscount = ({
  bundles,
  lineItems,
}: IGetActiveBundleDiscountProps) => {
  const activeBundles: IGetActiveBundleDiscountProps['bundles'] = [];

  const lineItemProductVariantIds = lineItems.map(
    (lineItem) => lineItem.sku.productVariantId
  );

  bundles.map((bundle) => {
    const bundleProductVariantIds = bundle.variants.map(
      (variant) => variant.id
    );

    if (
      bundleProductVariantIds.length > 0 &&
      bundleProductVariantIds.every((id) =>
        lineItemProductVariantIds.includes(id)
      )
    ) {
      activeBundles.push(bundle);
    }
  });

  return activeBundles;
};
