import { TypeOf, number, string, z } from 'zod';

const CreateNotificationSchema = z.object({
  email: string().email('Please enter a valid email.'),
  skuId: number(),
});

type ICreateNotificationSchema = TypeOf<typeof CreateNotificationSchema>;

export type { ICreateNotificationSchema };

export { CreateNotificationSchema };
