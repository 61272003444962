import { VariantProps, tv } from 'tailwind-variants';
import { Box, IBoxProps } from '../Box';
import { textSizes } from '@/src/packages/theme';
import { cn } from '@/src/packages/utils/cn';

export const paragraphStyles = tv({
  variants: {
    size: {
      lead: textSizes({ textSize: 'lead' }),
      large: textSizes({ textSize: 'lg' }),
      default: textSizes({ textSize: 'base' }),
    },
  },
});

export type IParagraphProps = Omit<IBoxProps, 'size'> &
  VariantProps<typeof paragraphStyles>;

export const Paragraph = (props: IParagraphProps) => {
  const { children, className, size = 'default', ...rest } = props;

  return (
    <Box as="p" className={cn(className, paragraphStyles({ size }))} {...rest}>
      {children}
    </Box>
  );
};
