import { z } from 'zod';

export const FetchCurrenciesSchema = z
  .object({
    id: z.number(),
    iso: z.string(),
    symbol: z.string().nullable(),
    conversionRate: z.number(),
    isDefault: z.boolean(),
  })
  .array();

export type IFetchCurrencies = z.TypeOf<typeof FetchCurrenciesSchema>;
