import { FetchCheckoutResponseDto } from '../../schemas/checkout.schema';
import { TypeOf } from 'zod';

export const calculateTotalItems = (
  lineItems: TypeOf<typeof FetchCheckoutResponseDto>['lineItems']
) => {
  let totalItems = 0;

  lineItems.map((lineItem) => (totalItems += lineItem.quantity));

  return totalItems;
};
