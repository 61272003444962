import { initContract } from '@ts-rest/core';
import { paths } from '../paths.enum';
import {
  FetchStoreSettingsDto,
  SignupToNewsletterDto,
} from '../schemas/settings.schema';
import { object, string } from 'zod';

const c = initContract();

export const storeSettingsContract = c.router({
  getStoreSettings: {
    method: 'GET',
    path: paths.settings,
    responses: {
      200: FetchStoreSettingsDto.nullable(),
      400: object({ message: string() }),
    },
  },
  signUpToNewsletter: {
    method: 'POST',
    path: '/newsletter',
    responses: {
      200: null,
      400: object({ message: string() }),
    },
    body: SignupToNewsletterDto,
  },
});
