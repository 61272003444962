import { FetchShippingDestinationsDto } from '../schemas/shipping-destinations.schema';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const shippingDestinationsContract = c.router({
  fetchShippingDestinations: {
    method: 'GET',
    path: '/shipping-destinations',
    responses: {
      200: FetchShippingDestinationsDto,
    },
  },
});
