export enum pathNames {
  'HOME_INDEX' = 'HOME_INDEX',
  'ACCESSIBILITY_INDEX' = 'ACCESSIBILITY_INDEX',
  'MODERN_SLAVERY_STATEMENT_INDEX' = 'MODERN_SLAVERY_STATEMENT_INDEX',
  'ABOUT_INDEX' = 'ABOUT_INDEX',
  'CONTACT_INDEX' = 'CONTACT_INDEX',
  'COLLECTIONS_INDEX' = 'COLLECTIONS_INDEX',
  'MATERIALS_INDEX' = 'MATERIALS_INDEX',
  'MATERIALS_SINGLE' = 'MATERIALS_SINGLE',
  'MANUFACTURING_INDEX' = 'MANUFACTURING_INDEX',
  'MANUFACTURING_SINGLE' = 'MANUFACTURING_SINGLE',
  'FAQS_INDEX' = 'FAQS_INDEX',
  'MAKERS_INDEX' = 'MAKERS_INDEX',
  'MAKERS_SINGLE' = 'MAKERS_SINGLE',
  'CERTIFICATIONS_INDEX' = 'CERTIFICATIONS_INDEX',
  'CERTIFICATIONS_SINGLE' = 'CERTIFICATIONS_SINGLE',
  'BLOG_INDEX' = 'BLOG_INDEX',
  'BLOG_CATEGORY_INDEX' = 'BLOG_CATEGORY_INDEX',
  'CHECKOUT_INDEX' = 'CHECKOUT_INDEX',
  'CHECKOUT_PAYMENT' = 'CHECKOUT_PAYMENT',
  'CHECKOUT_SHIPPING_METHODS' = 'CHECKOUT_SHIPPING_METHODS',
  'CHECKOUT_CONFIRMATION' = 'CHECKOUT_CONFIRMATION',
  'BLOG_SINGLE' = 'BLOG_SINGLE',
  'SHOP_INDEX' = 'SHOP_INDEX',
  'RANGES_INDEX' = 'RANGES_INDEX',
  'RANGES_SINGLE' = 'RANGES_SINGLE',
  'PRODUCT_CARE_GUIDE_SINGLE' = 'PRODUCT_CARE_GUIDE_SINGLE',
  'DELIVERY_RETURNS_INDEX' = 'DELIVERY_RETURNS_INDEX',
  'BUNDLES_INDEX' = 'BUNDLES_INDEX',
  'BUNDLES_SINGLE' = 'BUNDLES_SINGLE',
  'PRODUCTS_INDEX' = 'PRODUCTS_INDEX',
  'PRODUCTS_SINGLE' = 'PRODUCTS_SINGLE',
  'REVIEWS_INDEX' = 'REVIEWS_INDEX',
  'NEWSLETTER_INDEX' = 'NEWSLETTER_INDEX',
  'PRESS_INDEX' = 'PRESS_INDEX',
  'TERMS_INDEX' = 'TERMS_INDEX',
  'PRIVACY_INDEX' = 'PRIVACY_INDEX',
  'PRODUCT_CARE_INDEX' = 'PRODUCT_CARE_INDEX',
  'LIFETIME_WARRANTY_INDEX' = 'LIFETIME_WARRANTY_INDEX',
  'INFLUENCERS_INDEX' = 'INFLUENCERS_INDEX',
  'SIZE_GUIDES_INDEX' = 'SIZE_GUIDES_INDEX',
}
